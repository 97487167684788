import clsx from "clsx";
import { toPairs } from "lodash";
import { useEffect, useState } from "react";
import { HiX } from "react-icons/hi";

import { useApi } from "../../contexts/ApiContext";
import Button from "../Button/Button";
import ProgressIndicator, { ProgressBadge } from "../ProgressIndicator";

const DISCLAIMER =
  "I understand that employment by the Company is contingent upon the \
        Company obtaining references and verifying my legal work status. I \
        understand that, if offered employment, such offer may be \
        conditioned upon satisfactory completion of a physical examination, \
        background checks and drug screen.";

const LAST_STEP = {
  notes: "Review Your Answers",
  id: "review",
};

export const getAnswerLabel = (questions, answers, fieldName) => {
  const question = questions.find((q) => q.field_name === fieldName);

  const selectedOption = question?.options?.find(
    (option) => option.value === answers[fieldName],
  );
  return selectedOption ? selectedOption.label : "Not answered";
};

const CompletionCard = () => {
  const radius = 24;
  const circumference = 2 * Math.PI * radius;

  return (
    <div className="flex">
      <ProgressBadge radius={radius} circumference={circumference} />
      <div className="ml-3">
        <h3 className="text-desktopNeutralDark">Questionnaire Completed</h3>
        <p className="text-desktopNeutralVeryDark">
          Your answers have been registered securely.
        </p>
      </div>
    </div>
  );
};

const InitialStep = ({ setStep }) => (
  <>
    <h3 className="text-desktopNeutralDark">Acknowledgement and Consent</h3>
    <div className="border-desktopPrimaryLight bg-desktopPrimaryLightest rounded-lg border w-full py-2 px-4 max-h-36 overflow-scroll">
      {DISCLAIMER}
    </div>
    <div className="flex justify-end">
      <Button
        onClick={() => setStep("questions")}
        className="h-10 px-4 text-white rounded-md border bg-primary hover:bg-primary/75"
      >
        Next
      </Button>
    </div>
  </>
);

const Questions = ({ errorApi, clearError, steps, onComplete, loading }) => {
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [question, setQuestion] = useState(steps[0]);

  useEffect(() => {
    setQuestion(steps[index]);
  }, [index, steps, setQuestion]);

  const advance = () => {
    if (!steps[index + 1]) {
      const application = toPairs(answers).map((pair) => ({
        name: pair[0],
        value: pair[1],
      }));
      onComplete({ application });
    } else {
      setIndex(index + 1);
    }
  };

  const goBack = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const totalSteps = steps.length;
  const isLastStep = index === steps.length - 1;
  const disabledNext = !isLastStep ? !answers[question.field_name] : false;

  const onChange = (q) => (e) => {
    const value = e.target.value;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [q.field_name]: value !== "default" ? value : "",
    }));
  };

  const eeoQuestions = steps.slice(0, -1);

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
          <div className="h-10 w-10 bg-gray-200 rounded-full animate-pulse"></div>
        </div>
      )}
      <div>
        <ProgressIndicator
          index={index}
          totalSteps={totalSteps}
          currentStep={question}
        />

        <hr className="border-t border-gray-300 my-4" />

        {isLastStep ? (
          <div className="space-y-4">
            {errorApi && (
              <div
                className="flex my-4 items-center bg-destopRedLightest border border-desktopRedVeryLight text-red-700 px-4 py-3 rounded relative mt-4 max-w-md"
                role="alert"
              >
                <button
                  onClick={clearError}
                  className="flex items-center justify-center border border-desktopVeryDark text-desktopVeryDark rounded-full mr-2 p-1"
                >
                  <HiX className="h-3 w-3" />
                </button>

                <span className="text-desktopVeryDark block sm:inline">
                  {errorApi}
                </span>
              </div>
            )}
            {eeoQuestions?.map((q) => (
              <div key={q.field_name} className="flex flex-col">
                <label className="text-desktopNeutralDark">{q.label}</label>
                <span className="text-desktopNeutralVeryDark">
                  {getAnswerLabel(eeoQuestions, answers, q.field_name)}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <label key={question.field_name} className="block mb-4">
            <span className="text-desktopNeutralDark">{question.label}</span>

            <select
              name={question.field_name}
              id={question.field_name}
              className={
                "mt-2 block w-full p-2 border border-gray-300 rounded-md text-gray-700 bg-white focus:outline-none focus:border-primary appearance-none"
              }
              onChange={onChange(question)}
              value={answers[question.field_name] || "default"}
              style={{
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="gray"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>')`, // Custom arrow icon
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 0.5rem center",
                backgroundSize: "1.25rem",
              }}
            >
              <option value="default" disabled="disabled">
                Please select...
              </option>
              {question.options?.map((o) => (
                <option key={o.value} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          </label>
        )}

        <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={goBack}
            className={clsx(
              "px-4 py-2 border rounded focus:outline-none bg-white text-primary border-primary hover:bg-primary/10",
              {
                "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
                  index === 0,
              },
            )}
            disabled={index === 0}
          >
            Back
          </button>

          <button
            onClick={advance}
            className={clsx("px-4 py-2 border rounded focus:outline-none", {
              "bg-primary hover:bg-primary/75 text-white": !disabledNext,
              "bg-desktopNeutralLight text-desktopNeutralMedium cursor-not-allowed":
                disabledNext,
            })}
            disabled={disabledNext}
          >
            {isLastStep ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

const EEOQuestions = ({ meta }) => {
  const { api } = useApi();

  const [errorApi, setErrorApi] = useState();
  const clearError = () => setErrorApi(null);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState("initial");
  const [questions, setQuestions] = useState([]);
  const completed = meta?.complete;

  const onComplete = async (payload) => {
    try {
      setLoading(true);
      await api.postApplication(meta.posting_id, payload);
      setStep("done");
    } catch (error) {
      setErrorApi(
        "There was an error while completing the questionnaire.. Try again or submit it manually.",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    api.getApplicationItems(meta.posting_id).then((q) => {
      setQuestions(q.data?.concat(LAST_STEP));
    });
  }, []);

  return (
    <div className="px-3 py-3 flex flex-col gap-4 max-w-full w-full border break-words bg-white text-wrap rounded-lg">
      {completed || step == "done" ? (
        <CompletionCard />
      ) : step === "initial" ? (
        <InitialStep setStep={setStep} />
      ) : (
        <Questions
          steps={questions}
          onComplete={onComplete}
          errorApi={errorApi}
          clearError={clearError}
          loading={loading}
        />
      )}
    </div>
  );
};

export default EEOQuestions;
